/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Mobile menu
        $('#simple-menu').sidr({
          side: 'right',
          displace: false
        });
        var $hamburger = $(".hamburger");
        $hamburger.on("click touchstart", function(e) {
          $hamburger.toggleClass("is-active");
        });

        // Enable submit button in magazine newsletter
        $('#termsAndCondition').change(function() {
          if ( $(this).prop("checked") ) {
            $(".sidebar-magazine .newsletter button").removeClass('disabled');
          } else {
            $(".sidebar-magazine .newsletter button").addClass('disabled');
          }
        });

        $(document).on( 'click', '.searchToggle', function( e ) {
          $('.search-container').toggle();
          $('.search-field').focus();
        });

        $(document).on( 'click', '.newsletterSubmit', function( e ) {
          e.preventDefault();
          if ( $('#termsAndCondition').prop('checked') ) {
            $email = $('#newsletterEmail').val();
            $subscribeUrl = "http://nordregio.us2.list-manage2.com/subscribe?u=bd2ac4c8984e4c0867865efde&id=3667666de8&MERGE0=" + $email;
            var win = window.open($subscribeUrl, '_blank');
            win.focus();
          } else {
            $('#termsAndCondition').addClass('flash animated');
            setTimeout(function () {
              $('#termsAndCondition').removeClass('flash animated');
            }, 1000);
          }
        });

        // Toggle mobile submenus
        $('.mobile .menu-item-has-children').prepend('<i class="toggleSub fa fa-2x fa-angle-down"></i>');
        // Step through mobile menu and open parent items to current page
        $('#menu-mobile-menu li.active').each(function(i, obj) {
            // $(this).children('.toggleSub').toggleClass("fa-angle-up", "fa-angle-up");
            $(this).children('.sub-menu').toggleClass('animate');
        });
        // Toggle submenu
        $('.toggleSub').on("click", function( e ){
          $(this).toggleClass("fa-angle-down", "fa-angle-down");
          $(this).toggleClass("fa-angle-up", "fa-angle-up");
          $(this).siblings('.sub-menu').toggleClass('animate');
        });

        // Toggles the taxonomy filter
        $('.filter-tab section > *').on("click", function( e ){
          $('.toggleTax').toggleClass("fa-angle-down", "fa-angle-down");
          $('.toggleTax').toggleClass("fa-angle-up", "fa-angle-up");
          $('.filter-wrapper:not(.locked)').toggleClass('animate');
        });

        // Create columns in the footer menu
        $('#menu-footer-menu li.menu-item-has-children').addClass('col-md-3');

        // Initiate slideshow
        $(".rslides").responsiveSlides({
          auto: false,             // Boolean: Animate automatically, true or false
          nav: true,             // Boolean: Show navigation, true or false
          prevText: "<",   // String: Text for the "previous" button
          nextText: ">",       // String: Text for the "next" button
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Init dynamic slideshow produced by slides in option page and implementet
        // by shortcode.
        $(".dynamic-slideshow").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.slideshow-pagination'
        });
        $('.slideshow-pagination').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: '.dynamic-slideshow',
          dots: false,
          arrows: true,
          nextArrow: '<i class="fa fa-chevron-right slick-next"></i>',
          prevArrow: '<i class="fa fa-chevron-left slick-prev"></i>',
          centerMode: true,
          focusOnSelect: true
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // Hero Grid Module animation
        setTimeout(function () {
          // $('.fast-animation').css({ "opacity" : "1" });
          $('.fast-animation').addClass('visibleOpacity');
        }, 300);
        setTimeout(function () {
          // $('.medium-animation').css({ "opacity" : "1" });
          $('.medium-animation').addClass('visibleOpacity');
        }, 1000);
        setTimeout(function () {
          // $('.slow-animation').css({ "opacity" : "1" });
          $('.slow-animation').addClass('visibleOpacity');
        }, 1500);
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, hours) {
  var d = new Date();
  d.setTime(d.getTime() + (hours*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

